.totalcard {
    display: flex;
    justify-content: space-around;
    /* padding: 1rem */
}

.totalcard-content {
    background-color: white;

}

.total-head {
    /* padding: 0.5rem; */
}

.total-head h4 {
    font-size: 1.5rem;
}

.table-edit-one {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-edit-one img {
    cursor: pointer;
    margin-left: .5rem;
}

.table-edit {
    display: flex;
    align-items: center;
    justify-content: space-between;

    /* position: absolute; */
}

.table-edit img {
    cursor: pointer;
    margin-left: 1rem;
}

.profile {
    display: flex;
    justify-items: space-between;
}

.patitent-view-profile-modal {
    height: 100rem;
    display: flex;
    justify-items: space-between;
}

.doctor-profile-view-modal {
    height: 100rem;
    display: flex;
    justify-items: space-between;
}

.profile-img {
    text-align: center;
    padding: 2rem 2rem 0rem 2rem;
}

.profile-img .d-image {
    width: 14rem;
    position: relative;
}

.profile-img img {
    box-shadow: 0 0 1px 0px #c8c0c0;
}

.star-img {
    position: absolute;
    left: 2rem;
    top: 3rem;
}

.profile-content {
    width: 75%;
    margin-top: 2.5rem;
    /* padding-right: 3rem; */
}

.d-profile-heading {
    font-size: 0.875rem !important;
    color: #1a1919;
}

.profile-content h4 {
    margin-top: 0.4rem;
    font-weight: normal;
    color: #1a1919;
    font-size: 0.875rem;
}

.profile-content p {
    color: #989898;
}

.profile-about {
    margin-top: 1.5rem;
}

.profile-time {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    padding-bottom: 3rem;
    /* align-items: center; */
}

.edit {
    margin-right: 1rem;
    margin-left: 1rem;
    color: var(--steel-blue);
    list-style: none;
    cursor: pointer;
}

.timing-schedule {
    /* display: flex; */
    align-items: center;
    flex-wrap: wrap;
    /* width: 60%; */
}

.saudi-edit {
    /* width: 40%; */
    margin-top: .5rem;
    color: var(--steel-blue);
    list-style: none;
    cursor: pointer;
}

.timing-schedule-body {
    display: flex;
    align-items: center;
    /* width: 50%; */
    margin: 1rem 1rem 0rem 0rem;
}

.day-form {
    background-color: #CEECFF;
    color: var(--picton-blue);
    padding: .2rem .5rem 0.2rem .5rem;
    border-radius: 0.25rem;
    width: 10rem;
    text-align: center;
}

.time-form {
    margin-left: .5rem;
}



.user-table-past {
    padding: 0rem 1rem 1rem 1rem;
}

.user-health {
    display: flex;
    margin-top: 1rem;
    padding-bottom: 1rem;
}

.user-family {
    margin-left: 3rem;
}


@media (max-width:720px) {
    .totalcard {
        flex-direction: column;
        padding: 0.5rem;
    }

    .profile {
        flex-direction: column;
    }

    .profile-img .d-image {
        width: 100%;
    }

    .profile-content {
        width: 100%;
        padding: 0.5rem;
    }

    .profile-time {
        flex-direction: column;
    }
}