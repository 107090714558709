.online{
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

   width: 100%;
}
.online-img-content {
  margin: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.online-content h2{
  font-size: 1.75rem;
  font-weight: 600;
}
.online-content p{
  color: #707070;
  font-size: 1rem;
}
.online img{
   width: 15rem;
   height: 15rem;
 
    
}
.online-para {
  margin-top: 1.5rem;
  text-align: center;
  font-weight: normal !important;
  font-size: 1rem;
  color: #707070;
}
.online-design{
  position: absolute;
  right: 0;
}
 .online-design img{
  width:200px
}
@media (max-width: 720px) {
  .online img {
    width: 100%;
    margin: 0;
  }
  .online {
    padding: 1rem;
  }
  .online-img-content {
    margin: 0;
    display: flex;
    justify-content: center;
  }
}
