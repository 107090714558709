@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,200;1,300&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700|Sanchez&display=swap");

:root {
    --old-lace: #fff4e8;
    --mazie: #f4c548;
    --honeydew: #e9ffe8;
    --yellow-green: #16cb0e;
    --beer: #f28519;
    --picton-blue: #43afe9;
    --tufts-blue: #3a9cd8;
    --steel-blue: #2f77b6;
    --eerie-black: #1a1919;
    --dark-silver: #707070;
    --spanish-gray: #989898;
    --argent: #c4c0c0;
    --chinese-silver: #cbcbcb;
    --flash-white: #f0f0f0;
    --footer: #e6f6ff;
    --border-color: #F0F0F0;
}


/* utility classes */
.text-capitalize {
    text-transform: capitalize;
}

.pointer{
    cursor: pointer;
}

@media (max-width: 599.95px) {
    .text-capitalize {
        font-size: 100% !important;
    }
}

.text-uppercase {
    text-transform: uppercase;
}

.width-full {
    width: 100%;
}

.rs-picker-menu {
    z-index: 2000 !important;
}

.disabled-only {
    cursor: not-allowed;
}

.in-review-text {
    color: '#F28519' !important
}

.d-none {
    display: none;
}

.textarea-only-x {
    resize: horizontal;
}

.textarea-only-y {
    resize: vertical;
}

* {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    /* transition: 0.9s linear; */
    text-decoration: none;
    /* text-transform: capitalize; */
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

.hero {
    background-image: url("./assets/images/Rectangle\ 10\ \(1\).svg");
    background-repeat: no-repeat;
}

button {
    cursor: pointer;
}

.theme-button {
    border-radius: 1.5rem !important;
}

img {
    cursor: pointer;
    transition: .4s all;
    object-fit: contain !important;
}


button[disabled] {
    opacity: 0.8;
    cursor: not-allowed;
    transition: .4s all;
    color: #C4C0C0;
    border-color: #C4C0C0;
}


/* width */
.thin-scrollbar::-webkit-scrollbar {
    display: none;
    width: 10px;
}


/* Track */
.thin-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.thin-scrollbar::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
}









.file-input {
    width: 60%;
    padding: .5rem;
    border: 1px solid var(--argent);
    border-radius: 0.25rem;
    outline: none;
    margin-top: .5rem;
    font-size: 1rem;
    outline: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('./assets/images/Group\ -41.png');
    background-repeat: no-repeat;
    background-size: 7rem;
    background-position: calc(100% - 3px);
    cursor: pointer;
}

::file-selector-button {
    display: none;

}

.back-btn {
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    background-color: transparent;
    font-size: 1rem;
    cursor: pointer;
}

.rc-time-picker-clear {
    line-height: 15px !important;
}

.sweet-loader {
    width: 50%;
    margin: 20rem auto;
    display: flex;
    justify-content: center;
}


.filter-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
}

.filter-field p {
    margin-left: 1.5rem;
}

.filter-search {
    display: flex;
    align-items: center;
    margin-right: 1.6rem;
    margin-top: 0.5rem;
}

.filter-button {
    /* width: 10rem; */
    margin-left: 1rem;
}

.filter-button button {
    border: 1px solid var(--picton-blue);
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    padding-top: .4rem;
    border-radius: 2rem;
    padding-bottom: .4rem;
    background-color: white;
    cursor: pointer;
    color: var(--picton-blue);
    font-size: 1rem;
}

.user-past-card {
    margin: auto;
    width: 15rem;
    height: 2.2rem;
    background-color: #fff;
    border-radius: 0.2rem;
    display: flex;
    overflow: hidden;
    align-items: center;
    border: 1px solid var(--argent);
}

.user-past-label-icon {
    width: 2.5rem !important;
}

.user-past-card input {
    width: 80%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    font-size: 1rem;
    /* color: var(--argent); */
    padding: 0 1rem;
    text-transform: none;
}

.user-past-card img {
    width: 3rem;
    padding-right: 1rem;
    color: var(--argent);
    cursor: pointer;
}

@media (max-width:720px) {
    .filter-field {
        flex-direction: column;
    }

    .filter-field p {
        margin: 0;
    }

    .filter-search {
        flex-direction: column;
        margin-left: 1.6rem;
    }

    .filter-search-field input {
        /* padding: 0; */
        width: 100%;
    }

    .filter-button {
        width: 100%;
        margin: 0;
    }

    .filter-button button {
        width: 100%;
    }
}


/* Chat arrow message */
.arrow_box {
    position: relative;
    background: #88b7d5;
}

.arrow_box:after {
    right: 100%;
    top: 24.5%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #88b7d5;
    /* border-width: 30px; */
    border-width: 0px 30px 30px 0px;
    margin-top: -30px;
}


.right-chat-msg {
    background-color: red;
}

.left-chat-msg {
    background-color: green;

}

.arrow_box {
    position: relative;
    background: #88b7d5;
    padding: 0.2rem 1rem;
    border-radius: 5px;
    border-top-left-radius: 0px;
}

.arrow_box:after {
    right: 100%;
    top: 37%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #88b7d5;
    /* border-width: 30px;  */
    border-width: 0px 30px 30px 0px;
    margin-top: -30px;
}

.arrow_box2 {
    position: relative;
    background: gray;
    padding: 0.2rem 1rem;
    border-radius: 5px;
    border-top-left-radius: 0px;
}

.arrow_box2:after {
    left: 100%;
    /* right: 0px; */
    top: 40.5%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-right-color: gray;
    border-width: 51px 0 0 36px;
    margin-top: -30px;
    z-index: 10;
}


.talk-bubble {
    /* margin: 40px; */
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    background-color: rgb(240, 240, 240);

}

/* .talktext2{background: yellow !important; color: #000000 !important;} */

.triangle.left-top:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -20px;
    right: auto;
    top: 0px;
    bottom: auto;
    border: 22px solid;
    border-color: rgb(240, 240, 240) transparent transparent transparent;
}

.triangle.right-top:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -20px;
    top: 0;
    bottom: auto;
    border: 32px solid;
    border-color: rgb(240, 240, 240) transparent transparent transparent;
    /* border-color: yellow; */
}

.talktext {
    padding: 1em;
}

.my-5 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.not-selectable {
    user-select: none !important;
}

.color-red {
    color: red;
}

/* over ride mui css */
.Mui-selected {
    background-color: var(--steel-blue) !important;
    color: #ffffff !important;
}

.MuiTabs-indicator {
    display: none;
}