
.custom-page{
	width: 100%;
    padding: 1rem;
    /* margin: 0 auto; */
    display: flex;
    justify-content: space-between;
    /* height: rem; */
}
.custom-page button{
    border: none;
    color: var(--steel-blue);
    font-size: 1rem;
    padding: .2rem;
}
.page-container{
    /* width: 80%; */
    display: flex;
    justify-content: flex-end;
}
.custom-input{
    text-align: center;
    border: 1px solid var(--argent);
    padding: 0.2rem 1.3rem 0.2rem 1.3rem;
    border-radius: 0.25rem;
    margin: 0rem .5rem 0 0.5rem;
}