.side-back {
    background-color: #fcfcfc;
    margin-top: .5rem;
}

.side-container {
    display: flex;
    justify-items: center;

    padding: 2rem 0;
}

.side-right {
    width: 95%;
    margin: auto;
}

.side-right-content {
    border: 1px solid var(--argent);
    background-color: white;
    border-radius: 0.3rem;
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
}

.side-right-content h5 {
    font-weight: normal;
    font-size: 1rem;
}

.side-right-head {
    border-bottom: 1px solid var(--argent);
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: space-between;
}

.side-right-head h2 {
    /* margin-left: 1rem; */
    font-size: 1.2rem;
    padding: .5rem;
    color: var(--eerie-black);
}



.side-left {
    width: 20%;
    padding: 0rem 1rem;
}

.side-left ul {
    list-style: none;
}

.side-left ul li {
    /* margin-top: 1.5rem; */
}

.side-left ul .tablink {
    display: block;
    /* text-align: center; */
    border-radius: 2rem;
    padding: 1rem 2rem;
    cursor: pointer;
}

/* .side-left ul .tablink:active {
    color: white;
}

.side-left ul .tablink:visited {
    color: black;
} */

.active-side {
    background-color: var(--tufts-blue);
    color: white;
}


@media (max-width:720px) {
    .side-container {
        flex-direction: column;
    }

    .side-right {
        width: 100%;
        margin: 0;
        padding: .5rem;
    }

    .side-left {
        width: 100%;
    }

    .side-right-head {
        flex-direction: column;
    }
}