.search-container {
  background-image: url("../../assets/images/Rectangle\ 10\ \(1\).svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.5rem 0rem;
  margin-bottom: 2rem;
}
.search-container h2 {
  text-align: center !important;
  font-size: 2rem;
}
.input-search {
  width: 30%;
}
.input-search input {
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 4px;
  width: 100%;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
}
.doctorcard {
  /* height: 50vh; */
  padding: 0 2rem;
  display:grid;
  grid-template-columns: 1fr 1fr;
}
.doctor-content {
  border: 2px solid var(--argent);
  margin: 1rem;
  border-radius: 10px;
  padding: 0.8rem;
  display: flex;
  background-color: white;
}
.doctor-search-img-1{
  width: 30%;
  /* height: 10rem; */
}
.doctor-search-img-1 .doctor-img{
  height: 100px;
  width: 100%;
  margin: auto;
  border-radius: .25rem;
}
.icon-body{
  width: 100%;
  padding:0rem 1rem 0rem;
}
.icon-user-container{
    width: 100%;
    display: flex;
     align-items: center;
    justify-content: space-between;
}
.childpara{
    color: var(--dark-silver);
    font-size: .875rem;
    margin-top: .2rem;
}
.sar {
  font-weight: bold;
  color: var(--steel-blue);
  margin-top: 0.3rem;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}
.del {
  font-size: 0.7rem;
  text-decoration: line-through;
  margin-left: 0.5rem;
}
.button-container {
  display: flex;
  justify-items: center;
  margin-top: 1rem;
  align-items: center;
}
.button-container .book {
  display: flex;
  border: none;
  outline: none;
  border-radius: 1rem;
  cursor: pointer;
  color: white;
  padding:0.4rem 1rem;
  font-weight: 500;
  background-color: var(--steel-blue);
}
.button-container .phone {
  margin:0 1rem 0 1rem;
  cursor: pointer;
}
.button-container .video {
  display: flex;
  cursor: pointer;
  margin-left: 1rem;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding-left: 7.5rem;
  padding-right: 7.5rem;
  padding-top: 1rem;
  padding-bottom: 7rem;
}
.page-btn {
  display: flex;
  justify-items: center;
}
.page-btn button {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
img {
  max-width: 100%;
}

.searchcard {
  margin: 1.3rem auto;
  width: 30rem;
  height: 3rem;
  background-color: #fff;
  border-radius: 0.2rem;
  display: flex;
  overflow: hidden;
  align-items: center;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
}
.search-icon {
  width: 2.5rem !important;
}
.searchcard input {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  font-size: 1rem;
  color: var(--argent);
  padding: 0 1rem;
  text-transform: none;
}
.searchcard img {
  width: 3rem;
  padding-right: 1rem;
  color: var(--picton-blue);
  cursor: pointer;
}
@media (max-width: 720px) {
  .search-container{
    padding: 0.5rem;
  }
  .doctorcard {
    /* flex-direction: column; */
    height: auto;
    padding: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  .doctor-content {
    width: 100%;
    flex-direction: column;
    margin: 0;
    margin-top: 1rem;
      }
      .searchcard {
width: 100%;
      }
       
      .input-search{
          width: 100%;
          padding: 1rem;
      }
      .icon-body{
          margin: 0;
          margin-top: 1rem;
      }
      .icon-left{
          margin: 0;
      }
      .icon-right{
          margin-left: 3rem;
      }
      .sar{
          justify-content: space-between;
          font-size: .9rem;
      }
      .button-container .book{
        width: 100%;
        justify-content: center;
      }
      .button-container .phone{
        margin: 0;
        margin-top: .9rem;
        justify-content: center;
      }
      .button-container .video{
        margin: 0;
        justify-content: center;
        margin-top: .9rem;
      }
      .button-container{
          flex-direction: column;
      }
      .pagination{
          margin: 0;
          padding: 1.2rem;
      }
  }