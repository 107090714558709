.dicom-container {
  width: 100%;
  /* height: 100vh; */
  color: white;
  background-color: black;
  /* margin-top: 2rem; */
 
}

.dicom-tab-btn{
  display: flex;
  justify-content: center;
}
.tab-content {
  border: 1px solid gray;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}
.dicom-image-container {
  display: flex;
  /* justify-content: space-between; */
}
.dicom-image-left {
  width: 10%;
  padding: 1rem;
  height: 10rem;
}
.dicom-image-left .card{
    display: flex;
    flex-direction: column;
    width: 100px;
    justify-content: center;
    align-items: center;
}
.dicom-image-left p {
  text-align: center;
  font-size: 1rem;
  font-weight: normal;
}
.dicom-image-right {
  border: 1px solid blue;
  width: 85%;
  margin: 1rem auto;
  
  
}
.dicom-tab-btn button{
  padding: 0.5rem;
  border-radius: 0;
  width: 100%;
  cursor: pointer;
  /* margin: 1re; */
}
.dicom-image-contain{
    width: 100%; 
   display: flex;
  justify-content: space-between;
  padding: 0rem 1rem 0rem 1rem;
 
}
.left-dicom-images{
  width: 18%;
  border: 1px solid purple;
  height: calc(100vh - 165px);
}
.left-contain-images{
  border: 1px solid blue;
  width: 90%;
  padding: 1rem;
  margin: .8rem;
  /* height: calc(100vh - 560px); */
}
.left-contain-images img{
  width: 100%;
  /* height: calc(100vh - 600px); */
}
.mri-img{
  
    width: 81%;
    height: calc(100vh - 165px);
   border: 1px solid purple;
  display: flex;
  justify-content: center;

}
.zoom{
  position: absolute;
  top: 39rem;
  left: 20rem;
}
.zoom-wc{
  position: absolute;
  top: 39rem;
  right: 2rem;
}