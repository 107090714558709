.review {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.reviewcard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: 26rem;
}

.reviewcard-content {
    width: 49%;
    border: 1px solid var(--argent);
    border-radius: 0.6rem;
    padding: 1rem;
    margin-top: 1.2rem;
}

.review-rating {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.review-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.4rem 1rem 0.4rem 1rem;
    border: 1px solid var(--argent);
    border-radius: .25rem;
    /* justify-content: space-between; */
}

.review-btn-container select {
    /* padding: 0.4rem 1rem 0.4rem 1rem; */
}

.review-btn-container span {
    margin-right: 1rem;
    color: #C4C0C0;
    font-size: 1rem;
}

.review-btn-container select {

    border: none;
    outline: none;

}

.review-btn-container button {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: white;
    border: 1px solid var(--argent);
    border-radius: 0.3rem;
}

.review-btn-container button span {
    color: var(--argent);
}

.review-date {
    display: flex;
    align-items: center;
    border: 1px solid var(--argent);
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.3rem;
    background-color: white;
}

.review-date input {
    border: none;
    text-transform: uppercase;
}

.review-date p {
    color: var(--argent);
    /* margin-left: 1rem; */
}

.review-list p {
    color: var(--argent);
    font-size: 0.9rem;
}

.review-list h4 {
    font-weight: normal;
    font-size: 1rem;
    color: #1A1919;
}

.review-text {
    margin-top: 1rem;
}

.review-text h5 {
    color: var(--argent);
    font-size: 0.9rem;
    font-weight: normal;
}

.review-text p {
    font-size: 0.9rem;
}

@media (max-width:720px) {
    .review {
        flex-direction: column;
    }

    .reviewcard {
        flex-direction: column;
    }

    .reviewcard-content {
        width: 100%;
    }

    .review-date {
        flex-direction: column;
    }

    .review-btn-container {
        width: 100%;
    }

    .review-date {
        width: 100%;
        margin-top: 1rem;
    }

    .review-rating {
        flex-direction: column;
    }

}