@keyframes up-down {
    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }
}

@keyframes up-down-chat {
    0% {
        transform: translateY(5px);
    }

    50% {
        transform: translateY(-5px);
    }
}

@keyframes mymove {
    50% {
        transform: rotate(180deg);
    }
}

@keyframes pulse {
    0% {
        /* opacity: 0.8; */
    }

    50% {
        transform: scale(1.1);
        /* opacity: 0.8; */
    }
}

@keyframes hello-goodbye {
    0% {
        transform: translateY(-60px) rotateX(0.7turn);
        opacity: 0;
    }

    50% {
        transform: none;
        opacity: 1;
    }

    100% {
        transform: translateX(230px) rotateZ(90deg) scale(0.5);
        opacity: 0;
    }
}

/* @keyframes stretch {
    0%, 100% {
    transform: scaleX(1.1);
    }
    50% {
        transform: scaleX(1.8);
        background: darkturquoise;
    }
  }
   */

/* @keyframes drop {
    0% {transform: translateY(-200px) scaleY(0.9); opacity: 0;}
    5% {opacity: .7;}
    50% {transform: translateY(0px) scaleY(1); opacity: 1;}
    65% {transform: translateY(-17px) scaleY(.9); opacity: 1;}
    75% {transform: translateY(-22px) scaleY(.9); opacity: 1;}
    100% {transform: translateY(0px) scaleY(1); opacity: 1;}
  } */

  @keyframes slidein {
    from {
      margin-left: 100%;
      width: 300%;
    }
  
    to {
      margin-left: 0%;
      width: 100%;
    }
  }
/* 
@keyframes transition {
    0%   {background-color:red; left:0px; top:0px;}
    25%  {background-color:yellow; left:200px; top:0px;}
    50%  {background-color:blue; left:200px; top:200px;}
    75%  {background-color:green; left:0px; top:200px;}
    100% {background-color:red; left:0px; top:0px;}
  } */

@keyframes transitionn {
    0%   { left:0px; top:-200px;}
    /* 25%  { left:200px; top:0px;}
    50%  { left:200px; top:200px;}
    75%  { left:0px; top:200px;} */
    100% { left:0px; top:0px;}
  }