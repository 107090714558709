.footer {
  width: 100%;
  padding: 2rem 2rem;
  background-color: var(--footer);
  position: relative;
  z-index: 1;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.curs {
  cursor: pointer;
}
.footer-link h6,
.footer-menu1 .a {
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: 600;
  color: var(--spanish-gray);
}
.footer-link img {
  cursor: pointer;
}
.footer-link p {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}
.footer-link p a {
  margin-right: 1rem;
}
.footer-menu {
  list-style: none;
}
.footer-menu li {
  margin-top: 1.2rem;
  font-size: 1rem;
}
.footer-menu a {
  color: var(--eerie-black);
}
.footer-menu1 {
  list-style: none;
}
.footer-menu1 li {
  margin-top: 0.4rem;
  font-size: 1rem;
}
.footer-menu1 a {
  color: var(--eerie-black);
}

.footer-copy {
  display: flex;
  justify-content: space-between;
}
.footer-copy .term {
  color: #000;
  display: flex;
  justify-items: center;
  cursor: pointer;
}
.footer-copy .term span {
  margin-left: 1rem;
  color: #000;
}
.footer-copy p {
  color: #000;
  font-weight: 400;
}
.privacy {
  margin-left: 1rem;
  color: #000;
  cursor: pointer;
}
.footer-hero {
  background-color: var(--picton-blue);
  width: 100%;
  color: white !important;
  padding: 2rem 2rem 0 6rem;
  position: relative;
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
}
.footer-hero h2 {
  /* font-size: 2.125rem; */
  font-weight: 500 !important;
  /* text-align: left; */
}
.footer-hero p {
  margin-top: 0.7rem;
  text-align: left;
  color: #fff;
  font-weight: 300;
  line-height: 1.75rem;
}
.down-img-container {
  width: 15%;
  position: absolute;
  /* left: 6rem; */
  bottom: -0.5rem;
}
.mobile-app {
  width: 45%;
  margin: 0 0 0 18rem;
}

.hero-form {
  width: 40%;
  margin-left: 2rem;
  margin-right: 1.5rem;
}
.hero-form p {
  margin: 0;
  font-weight: bold;
  /* text-align: right; */
}
.form-content {
  display: flex;
  margin-top: 1rem;
}
.form-content input {
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 2, 0.1);
  padding: 0.5rem 1rem;
  font-weight: 400;
  font-size: 1rem;
  height: 3rem;
  border: none;
  outline: none;
  border-radius: 4px;
}
.form-content .sms-btn {
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: var(--steel-blue);
  cursor: pointer;
  width: 10rem;
  padding: 0.8rem 1rem 1rem 1rem;
  height: 3rem;
  color: white;
  border-radius: 1.5rem;
  font-size: 0.9rem;
  border: none;
}
.hero-btn {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-top: 1.5rem;
}
.hero-btn .app-img {
  width: 8rem;
  height: 2.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: pointer;
}
.downmobile-img {
  cursor: pointer;
}
.email {
  margin-bottom: 1rem;
  cursor: pointer;
  text-decoration: "none";
  margin-top: 0;
  color: #000;
  font-size: 1rem;
  cursor: pointer;
}

.term-content {
  display: flex;
}
@media (max-width: 768px) {
  .footer-hero {
    width: 100%;
    padding: 1.5rem;
    flex-direction: column;
  }
  .form-content {
    flex-direction: column;
  }
  .form-content .sms-btn {
    padding-top: 1rem;
    margin-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-left: 0;
  }
  .hero-content {
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .down-img-container {
    width: 100%;
    position: relative;
  }
  .mobile-app {
    width: 100%;
    margin: 0;
  }

  .hero-img {
    width: 100%;
  }

  .hero-heading {
    width: 100%;
  }
  .hero-form {
    width: 100%;
    margin: 0;
  }
  .footer-copy {
    flex-direction: column;
  }
  .hero-btn {
    flex-direction: column;
  }
  .hero-btn .app-img {
    margin: 0;
    padding: 0;
  }
}
