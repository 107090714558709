.heading {
    font-size: 2rem;
    width: 90%;
    margin: 1rem auto;

}


.scrollable-div {
    width: 90%;
    margin: auto;
}