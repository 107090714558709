.fordoctors {
  width: 100%;
  padding: 0 5%;
}
.header-item {
  padding-right: 20%;
  margin-left: 8rem;
}
.header-item h2 {
  font-size: 1.75rem;
  font-weight: 600;
}
.fordoctors p {
color: #707070;
font-size: 1rem;
font-weight: normal;
}
.doctors-right {
  display: flex;
  justify-items: center;
  align-items: center;
  margin-top: 2rem;
  padding-right: 2rem;
  margin-left: 8rem;
}
.for-d-img img{
  width: 32.875rem;
  height:32.625rem ;
  /* max-width: 100%; */
  cursor: pointer;
}
.doctors-right img{
    margin-right : 1.25rem;
    margin-left : 1.25rem;
    max-height: 100%;
    width: 6rem;
    cursor: pointer;  
}

@media (max-width: 720px) {
  .doctors-right{
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
  
  .header-item {
    margin: 0;
    padding: 0;
  }
  .fordoctors {
    padding: 2rem !important;
  }
  .for-d-img img{
    max-width: 100%;
    height: auto;
  }
}
