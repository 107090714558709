.contact {
    background-image: url('../../assets/images/Rectangle\ 10\ \(1\).svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    object-position: center;
    padding-bottom: 9rem;
}

.contact-container {
    width: 65%;
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.email-container {
    border: 1px solid #e6e6e6;
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    display: flex;
    border-radius: 4px;
    justify-items: center;
    align-items: center;
    padding: 0.5rem;
}

.email-head {
    margin: 0 1rem 0 1rem;
}

.contact-form {
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin-top: 1rem;
    border-radius: 4px;
    padding-bottom: .5rem;
}

.get-in {
    background-image: url('../../assets/images/Rectangle\ 2764.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    object-position: center;
    border: none;
    outline: none;
    width: 12rem;
    padding: .5rem;
    text-align: center;
    font-size: 1.2rem;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    font-weight: bold;
    margin: 0 auto;
}

.contact-form-container {
    margin: 0 auto;
    width: 80%;
}

.contact-input-container {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;
}

.contact-input-body {
    width: 45%;
}

.input-one {
    border: 1px solid var(--argent);
    outline: none;
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    height: 3.7rem;
    margin-top: 1rem;
    border-radius: 0.25rem;
}

.input-two {
    border: 1px solid var(--argent);
    outline: none;
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    color: #909090;
    height: 3.7rem;
    margin-top: 1rem;
    margin-left: 2rem;
    border-radius: 0.25rem;
}

.contact-select-field {
    border: 1px solid var(--argent);
    outline: none;
    padding: 1rem;
    height: 3.7rem;
    font-size: 1rem;
    margin-top: 1rem;
    color: #909090;

    border-radius: 0.25rem;
    appearance: none;
    margin-left: 2rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('../../assets/images/Path\ -12.png');
    background-repeat: no-repeat;
    background-size: .85rem;
    background-position: calc(100% - 3px);
    width: 100%;
}

.contact-text-area textarea {
    width: 93.8%;
    margin-top: 2rem;
    border: 1px solid var(--argent);
    border-radius: .25rem;
    padding: 1rem;
    height: 10rem;
    font-size: 1rem;
    outline: none;
}

.submit-btn {
    /* text-align: center; */
    background-color: var(--steel-blue);
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border: none;
    outline: none;
    border-radius: 2rem !important;
    margin: 2rem auto;
    padding: .5rem !important;
    color: white;
    font-size: 1rem;
    cursor: pointer;
}

.error-one {
    margin-left: 2rem;
}

.fa-chevron-right {
    margin-left: .4rem;
    /* font-size: 0.8rem; */
}

.contact-map {
    width: 70%;
    margin: 0 auto;
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
    /* padding-top: 2rem; */
    /* padding-bottom: 2rem; */
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    justify-items: center;
}

.contact-add {
    width: 70%;
}

.contact-map-i {
    width: 60%;
}

.add {
    padding: 1rem;
}

.add h4 {
    font-size: .9rem;
}

.add p {
    font-size: 0.8rem;
    margin-top: .5rem;
}

@media (max-width:720px) {
    .contact-container {
        width: 100%;
        padding: 0.5rem;
    }

    .contact-form-container {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .contact-input-container {
        flex-direction: column;
        width: 100%;
    }

    .contact-input-body {
        width: 100%;
    }

    .input-two {
        margin-left: 0;
    }

    .contact-select-field {
        margin-left: 0;
    }

    .contact-text-area textarea {
        width: 100%;
    }

    .submit-btn {
        font-size: .8rem;
        width: 10rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .contact-map {
        flex-direction: column;
        width: 100%;
        padding: .5rem;
    }

    .contact-add {
        width: 100%;
    }

    .contact-map-i {
        width: 100%;
    }
}