@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

.wrapper-upcoming {
    border: 1px solid var(--argent);
    border-radius: 0.625rem;
    background-color: white;
}

.wrapper-upcoming-head {
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: space-between;
    border-bottom: 1px solid var(--argent);
}

.wrapper-up-para {
    display: flex;
    align-items: center;
}

.wrapper-up-para p {
    color: #393939;
    font-size: 1rem;
    font-weight: bold;
}

.wrapper-up-card {
    display: flex;
    padding: .5rem;

}

.wrapper-up-card-body {
    border: 1px solid var(--argent);
    border-radius: .6rem;
    /* padding: .5rem; */
}

.wrapper-card-head {
    padding: 0.5rem 1rem;
    /* padding: .2rem .5rem 0rem .5rem; */
}

.wrapper-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: .2rem; */
    margin: 0.5rem 0;

}

.wrapper-card-content p {
    font-size: .75rem;
    color: #989898;
}

.wrapper-card-content .wrapper-para {
    color: #1A1919;
    font-size: .75rem;
    text-align: center;
}


.upcoming {
    background-color: #d5f1ff;
    padding: 0.2rem 1rem;
    width: 6.5rem;
    font-weight: 700;
    color: var(--picton-blue);
    border-top-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    width: 40%;
}

.view-resh-content {
    display: flex;
    justify-items: center;
    justify-content: space-between;
    margin: 1rem 0rem 1rem 0rem;
}

.view-button {
    border: 2px solid var(--picton-blue);
    background-color: white;
    color: var(--eerie-black);
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border-radius: 2rem;
    cursor: pointer;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}

.resch-button {
    /* border: 1px solid #CBCBCB; */
    border: 1px solid var(--picton-blue);
    background-color: white;
    /* color: #C4C0C0; */
    color: var(--picton-blue);
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-top: .2rem;
    padding-bottom: .2rem;
    border-radius: 2rem;
    margin-left: .3rem;
    margin-right: .3rem;
    cursor: pointer;
}

.doctor-tab-container {
    margin-top: 1rem;
    border: 1px solid var(--argent);
    /* padding: .5rem; */
    background-color: white;
    border-radius: 0.625rem;
}

.doctor-tab-container ul {
    display: flex;
    list-style: none;
    border-bottom: 2px solid var(--border-color);

}

.doctor-list-tab {
    font-size: 1rem;
    cursor: pointer;
    color: #989898;
    font-weight: 600;
    padding: 1rem;
}

.doctor-list-tab-1 {
    font-size: 1rem;
    cursor: pointer;
    color: #989898;
    font-weight: 600;
    margin-left: 4rem;
    padding: 1rem;
}

.doctor-list-active-tab {
    border-bottom: 2px solid var(--steel-blue);
    color: var(--steel-blue);
}

.tabel-tab {
    margin-top: 1rem;
    padding: 0rem 1rem 0rem 1rem;
}