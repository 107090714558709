.testimonial_container {
  width: 100%;
  padding: 0rem 4rem;
  margin-top: 4rem;
}
.testimonial_container h2 {
  text-align: center !important;
  font-size: 1.8rem;
}
.testcard {
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin-bottom:8rem;
}

.testimonial_card {
  color: #333;
  text-align: left;
}
.testimonial_card img {
  max-width: 3rem;
  vertical-align: middle;
  /* width: 60  px; */
  border-radius: 50%;
  margin-right: 1.5rem;
}
.testimonial_card .block-1 {
  display:block;
  border-radius: 0.5rem;
  border-bottom-left-radius: 0px ;
  height: 12rem;
  margin: 1rem 1rem 0;
  background-color: #E2EBF1;
  padding: 1rem;
}

.testimonial_card .arrow-1 {
  -webkit-clip-path: polygon(55% 0, 0 0, 0 71%);
  clip-path: polygon(55% 0, 0 0, 0 71%);
  width: 5rem;
  height: 3rem;
  margin: 0rem 0rem 0rem 1rem;
  background-color: red;
  background-color: #E2EBF1;
}
.testimonial_card .block-2 {
  display:block;
  border-radius: 0.5rem;
  border-bottom-left-radius: 0px ;
  height: 12rem;
  margin: 1rem 1rem 0;
  background-color: #F6EEE0;
  padding: 1rem;
}

.testimonial_card .arrow-2 {
  -webkit-clip-path: polygon(55% 0, 0 0, 0 71%);
  clip-path: polygon(55% 0, 0 0, 0 71%);
  width: 5rem;
  height: 3rem;
  margin: 0rem 0rem 0rem 1rem;
  background-color: red;
  background-color: #F6EEE0;
}
.testimonial_card .block-3 {
  display:block;
  border-radius: 0.5rem;
  border-bottom-left-radius: 0px ;
  height: 12rem;
  margin: 1rem 1rem 0;
  background-color: #E1F4E7;
  padding: 1rem;
}

.testimonial_card .arrow-3 {
  -webkit-clip-path: polygon(55% 0, 0 0, 0 71%);
  clip-path: polygon(55% 0, 0 0, 0 71%);
  width: 5rem;
  height: 3rem;
  margin: 0rem 0rem 0rem 1rem;
  background-color: red;
  background-color: #E1F4E7;
}
.testimonial_card .block-4 {
  display:block;
  border-radius: 0.5rem;
  border-bottom-left-radius: 0px ;
  height: 12rem;
  margin: 1rem 1rem 0;
  background-color: #E9F6FE;
  padding: 1rem;
}

.testimonial_card .arrow-4 {
  -webkit-clip-path: polygon(55% 0, 0 0, 0 71%);
  clip-path: polygon(55% 0, 0 0, 0 71%);
  width: 5rem;
  height: 3rem;
  margin: 0rem 0rem 0rem 1rem;
  background-color: red;
  background-color: #E9F6FE;
}
.testimonial_card .block-5 {
  display:block;
  border-radius: 0.5rem;
  border-bottom-left-radius: 0px ;
  height: 12rem;
  margin: 1rem 1rem 0;
  background-color: #F7E9E3;
  padding: 1rem;
}

.testimonial_card .arrow-5 {
  -webkit-clip-path: polygon(55% 0, 0 0, 0 71%);
  clip-path: polygon(55% 0, 0 0, 0 71%);
  width: 5rem;
  height: 3rem;
  margin: 0rem 0rem 0rem 1rem;
  background-color: red;
  background-color: #F7E9E3;
}
.testimonial_card .block-6 {
  display:block;
  border-radius: 0.5rem;
  border-bottom-left-radius: 0px ;
  height: 12rem;
  margin: 1rem 1rem 0;
  background-color: #F0EDFE;
  padding: 1rem;
}

.testimonial_card .arrow-6 {
  -webkit-clip-path: polygon(55% 0, 0 0, 0 71%);
  clip-path: polygon(55% 0, 0 0, 0 71%);
  width: 5rem;
  height: 3rem;
  margin: 0rem 0rem 0rem 1rem;
  background-color: red;
  background-color: #F0EDFE;
}
.testimonial_card .author {
  text-transform: uppercase;
  display: flex;
  margin: .5rem 1rem 0;
  align-items: center;
  color: black;
}
.testimonial_card .author h5 {
  opacity: 0.8;
  font-weight: 600;
  font-size: 1rem;
  /* margin-bottom: 5px; */
}
.author span {
  font-weight: 400;
  font-size: 0.9rem;
  text-transform: none;
  color: #707070;
}

@media (max-width: 720px) {
  .testimonial_container{
    padding: 0.5rem;
  }
  .testcard {
    margin: 0;
    padding: 0;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  figure.testcard1 {
    width: 90%;
    margin: 20px;
  }
  figure.testcard1 .arrow {
    right: 20rem;
  }
}