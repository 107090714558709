.slick-slider {
    /* margin-top: 2rem; */
    padding: 1rem;
}

.sliderr {
    padding: 1rem 2rem 1rem 2rem;
    /* width: 100%;s */
    overflow: hidden;
    margin-top: 1rem;
}

.slick-track {
    margin: auto;
}

.slidercontent p {
    margin-top: 5px;
    color: #707070;
    font-size: 1rem;
}

.slide-body p {
    /* display: flex; */
    text-align: center;

}

.slide-body img {
    width: 100%;
}

.slider-para {
    position: relative;
    bottom: 6rem;
    text-align: center;
}

.img-content {
    margin-top: 5px;
}

.chatpeople {
    padding: 2rem 8rem;
    background-color: #f7f7f7;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chatpeople h2 {
    font-weight: 600;
    text-align: left;
    font-size: 1.75rem;
}

.chatpeople p {
    color: #707070;
    font-size: 1rem;
}

.label {
    width: 50%;
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
}

.img {
    width: 8rem;
    cursor: pointer;
    /* margin: 1rem 2rem;  */
}

.item {
    width: 12rem;
    margin: 2.5rem 12rem;
    position: relative;
}

.item img {
    border-top-right-radius: 50%;
    max-width: 100%;
    height: 180px;
}

.item h4 {
    color: #1A1919;
    text-align: center;
    padding: 1rem;
    font-weight: 500;
    font-size: 1rem;
    opacity: 1;
}


.active {
    border-bottom: transparent;
}

.active:active {
    color: unset;
}

.active:visited {
    color: unset;
}

.design1 {
    position: absolute;
    right: 1rem;
    /* overflow: hidden; */
    bottom: -38rem;
}

.slide-category-image {
    /* height: 4rem;
  width: 4rem; */
}

.slide-category-image img {
    height: 100%;
    width: 100%;
}


@media (max-width:768px) {
    .chatpeople {
        flex-direction: column;
        padding: 2rem;
    }

    .label {
        flex-direction: column;
        /* width: 100%; */
        width: 100%;
        justify-content: center;
        margin: 2rem auto;
    }

    .img {
        margin-top: 2rem;
    }
}