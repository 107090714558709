.step-c {
    width: 100%;
}

.stepper {
    border: 1px solid var(--argent);
    width: 90%;
    margin: 5rem auto;
    padding-bottom: 1rem;
    border-radius: 0.25rem;
    margin-bottom: 10rem;
}

.step-border {
    border-bottom: 1px solid var(--argent);
}

.stepper-content {
    width: 62%;
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    justify-content: center;
    align-items: center;
}

.stepper-content .apt-para {
    margin-left: 0.5rem;
    color: var(--steel-blue);
    font-size: 0.75rem;
}

.stepper-content .fill {
    margin-left: 0.5rem;
    color: #c4c0c0;
    font-size: 0.75rem;
}

.line-img {
    margin-left: 0.5rem;
}

.first-content {
    width: 60%;
    border: 1px solid var(--argent);
    margin: 1rem;
    border-radius: 10px;
    padding: 0.8rem;
    display: flex;
    margin: 0 auto;
    align-items: center;
}

.first-body {
    width: 100%;
}

.icon-first-user-container {
    display: flex;
    justify-content: space-between;
}

.icon-first-left {
    display: flex;
    align-items: center;
    justify-items: center;
}

.first-child-para {
    color: var(--dark-silver);
    font-size: 0.875rem;
    margin-top: 0.2rem;
}

.first-sar {
    font-weight: bold;
    color: var(--steel-blue);
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
}

.first-sar button {
    margin-left: 1rem;
    padding: 0.3rem 2rem 0.3rem 2rem;
    border-radius: 2rem;
    background-color: transparent;
    border: 1px solid var(--steel-blue);
    color: var(--steel-blue);
}

.first-del {
    font-size: 0.7rem;
    text-decoration: line-through;
    margin-left: 0.5rem;
}

.first-para {
    color: #707070;
    font-size: 0.875rem;

    margin: 2rem 15rem 0 17.5rem;
}

.first-book-input {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    padding-bottom: 1rem;
}

.first-book-input label {
    color: #707070;
    font-size: 0.875rem;
}

.first-input-content {
    width: 28.8%;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.first-date {
    width: 100%;
    padding: 0.7rem 1rem 0.7rem 1rem;
    border: 1px solid var(--argent);
    border-radius: 0.3rem;
    outline: none;
}

.first-date-time {
    width: 100%;
    padding: 0.7rem 1rem 0.7rem 1rem;
    border: 1px solid var(--argent);
    border-radius: 0.3rem;
    outline: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: white;
    transition: .2s all;
}

.first-date-time[disabled] {
    background-color: rgba(0, 0, 0, 0.075);
}

.first-border {
    border-bottom: 1px solid var(--argent);
}

.first-btn-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.first-back {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.second-step {
    width: 60%;
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.book-for-new {
    display: flex;
    align-items: center;
}

.edit-second-input {
    display: flex;
    column-gap: 1rem;
    justify-content: space-between;
    margin-top: 2rem;
}

.edit-second-input input[type="text"],
.edit-select-field,
.user-date {
    width: 100%;
    padding: 0.5rem 1.25rem;
    margin: 0.5rem 0;
    display: block;
    background-color: white;
    height: 2.8rem;
    border: 1px solid var(--argent);
    border-radius: 0.25rem;
    box-sizing: border-box;
    /* text-transform: capitalize; */
    outline: none;
}

.edit-textarea-second textarea {
    border: 1px solid var(--argent);

    border-radius: 0.25rem;
    box-sizing: border-box;
    width: 100%;
    padding: 0.75rem 1.25rem;
    margin: 0.5rem 0;
    display: block;
    height: 10rem;
    outline: none;
}

.second-upload-file {
    width: 100%;
    margin-top: 1.5rem;
}

.second-file {
    border: 1px solid var(--argent);
    padding: 0.8rem;
    outline: none;
    border-radius: 0.25rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.third-step-content {
    width: 65%;
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.your-sum {
    text-align: center;
    font-size: 0.875rem;
    color: #707070;
}

.third-step {
    display: flex;
    justify-content: space-between;
    border: 1px solid #93e1ff;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    border-radius: 0.25rem;
    background-color: #f8fcff;
    /* padding-left: 1rem; */
}

.third-step-para p {
    margin-top: 1rem;
    font-size: 0.875rem;
    color: #1a1919;
}

.t-amount {
    font-size: 0.875rem;
    font-weight: 700;
}

.second-choose-p {
    margin-top: 1.5rem;
}

.second-payment-content {
    display: flex;
    margin-top: 0.5rem;
}

.choose-para {
    margin-left: 1rem;
    font-size: 0.875rem;
}

.back {
    width: 10rem;
    border: 1px solid blue;
    color: blue;
    height: 3rem;
    background-color: white;
    border-radius: 2rem;
}

.disabled {

    border: 1px solid gray;
    width: 10rem;
    color: grey;
    height: 3rem;
    border-radius: 2rem
}

.disabled:hover {
    cursor: not-allowed;
}

.second-p-card {
    display: flex;
    align-items: center;
    background-color: white;
    width: 50%;
    padding: 1rem;
    border: 1px solid var(--argent);
    cursor: pointer;
    border-radius: 0.25rem;
    margin-left: 1rem;
}

.second-p-card.tab-active {
    background-color: white;
    border: none;
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
}


input[type="radio"] {
    width: 1.15rem;
    height: 1.15rem;
}

.radio-para {
    margin-left: 0.5rem;
}

.second-p-card:hover {
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
    border: none;
}

.step-cancel-btn {
    border: 1px solid var(--steel-blue);
    border-radius: 2rem;

    background-color: white;
    color: var(--steel-blue);
    font-size: 1rem;
    padding: 0.6rem 3rem 0.6rem 3rem;
    margin: 1rem 1rem 0rem 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.proceed-btn {
    border: none;
    background-color: var(--steel-blue);
    color: white;
    font-size: 1rem;
    padding: 0.8rem 3rem 0.8rem 3rem;
    border-radius: 2rem;
    margin: 0rem 1rem 0rem 1rem;
}

.file-upload-container {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.file-upload-container-1 {
    margin-top: 1.5rem;
    display: flex;
    width: 60%;
    margin: 0 auto;
    justify-content: center;
    align-items: flex-start;
}

.dicom-file {
    width: 60%;
    margin: 0 auto;
    padding-bottom: 2rem;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.label-file {
    width: 50%;
    padding-right: 3rem;
}

.file-container {
    width: 50%;
    padding: .4em 1rem;
    border: 1px solid var(--argent);
    border-radius: 0.25rem;
    outline: none;
    font-size: 1rem;
    outline: none;

}

.upload-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.file-input-field {
    cursor: pointer;
}

.file-preview {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-between;
    margin-top: .8rem;
    padding: .5rem;
    border-radius: .25rem;
    background-color: #D5F1FF;
}

.file-preview-name {
    font-size: 1rem;
    font-weight: bold;
    color: #707070;
}

.upload-btn {
    background-color: var(--steel-blue);
    width: 20%;
    margin-top: 1rem;
    text-align: center;
    font-size: 1rem;
    color: white;
    padding: 0.5rem;
    border-radius: 2rem;
    cursor: pointer;
    outline: none;
}

.delete-icon {
    font-size: 1.5rem;
    cursor: pointer;
    color: red;
}

@media (max-width: 720px) {
    .step-c {
        width: 100%;
        padding: 0.5rem;
    }

    .stepper {
        width: 100%;
    }

    .stepper-content {
        flex-direction: column;
        width: 100%;
    }

    .first-content {
        width: 100%;
        flex-direction: column;
        margin: 0;
        margin-top: 1rem;
    }

    .first-img {
        width: 100%;
        display: block;
    }

    .first-book-input {
        flex-direction: column;
    }

    .first-input-content {
        width: 100%;
    }

    .first-btn-content {
        flex-direction: column;
    }

    .first-back {
        flex-direction: column;
    }

    .second-step {
        width: 100%;
        padding: 0.5rem;
    }

    .edit-second-input {
        flex-direction: column;
    }

    .second-upload-file {
        flex-direction: column;
    }

    .second-file {
        width: 100%;
    }

    .third-step-content {
        width: 100%;
        padding: 0.5rem;
    }

    .second-payment-content {
        padding: 0.5rem;
    }

    .second-p-card {
        width: 100%;
        margin: 0.5rem;
        flex-direction: column;
    }

    .second-p-card p {
        margin: 0;
        text-align: center;
    }

    .upload-file-container {
        flex-direction: column;
    }

    .choose-file-body {
        width: 100%;
    }
}