.register {
    background-image: url("../assets/images/Rectangle\ 10\ \(1\).svg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 4rem 3rem;
    width: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.register-container {
    background-color: #ffffff;
    box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
    padding: 2rem 0rem 2rem 0rem;
    display: flex;
    justify-content: space-evenly;
    border-radius: 0.5rem;
}

.register-image-contain {
    width: 55%;
    border-right: 1px solid var(--argent);
    display: flex;
    justify-content: center;
}

.register-image-contain img {
    padding: 2rem;
    height: 400px;
}

.register-form {
    padding: 1rem;
    width: 45%;
}

.form-c {
    display: flex;
    justify-content: center;
}

.radio-field {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.p-label {
    font-size: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

input[type="radio"] {
    border: 0px;
    width: 1.5rem;
    height: 1.5rem;
}

.register-input-field {
    text-align: center;
    margin: 2rem 0;
}

.phone-input {
    width: 72%;
    outline: none;
    font-size: 1rem;
    border-radius: 4px;
    text-align: center;
    margin: 1rem 0rem 0 2.5rem;
}

/* .register-input-field input {
    padding: 0.8rem 2rem;
    margin-top: 1rem;
    border-radius: 4px;
    border: 1px solid var(--argent);
    outline: none;
    font-size: 1rem;
} */

.btn-container {
    display: flex;
    justify-content: center;
    margin-top: .5rem;
}

.submit-btn {
    background-color: var(--steel-blue);
    cursor: pointer;
    width: 12rem;
    height: 3rem;
    padding: 0.6rem 1.2rem;
    color: white;
    font-size: 1rem;
    border-radius: 1.5rem;
    border: none;
    outline: none;
}

.register-para {
    margin-top: 1rem;
}

.register-para p {
    text-align: center;
}

.register-form h2 {
    text-align: center;
}

.error {
    color: red;
    width: 250px;
    margin-top: 0.5rem;
    font-size: 0.8rem;
    text-align: left;
    height: 0.5rem;
    /* margin-left: 5rem; */
}

.register-para p a {
    color: var(--steel-blue);
    text-decoration: underline;
}

.forgot-pass {
    margin-top: 1.5rem;
}

.forgot-pass p {
    color: var(--steel-blue);
}

.otp-head-para {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.resend-btn-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    margin-top: 2rem;
}

.resend-btn {
    border: none;
    background-color: var(--steel-blue);
    outline: none;
    color: white;
    width: 40%;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 2rem;
    cursor: pointer;
}

.password-input-parent {
    position: relative;
}

.password-input-child {
    position: absolute;
    top: 1.9rem;
    right: 1rem;
}

.forgot-password {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
    color: #43a6dc;
    font-size: medium;
    margin: 1.5rem 0;
    outline: none;
    background: none;
    border: none;
}

.forgot-password:disabled {
    opacity: 0.5;
}

.verify-btn {
    border: none;
    background-color: #16cb0e !important;
    outline: none;
    color: white !important;
    /* width: 40%; */
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 2rem !important;
    cursor: pointer;
}


@media (max-width: 720px) {
    .register {
        width: 100%;
        padding: 0.5rem;
    }

    .register-container {
        flex-direction: column;
        height: auto;
    }

    .register-image-contain {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid var(--argent);
    }

    .register-form {
        width: 100%;
    }

    .register-input-field input {
        /* margin-left: 1rem; */
    }
}

@media (max-width: 896px) {
    .register {
        width: 100%;
        padding: 0;
    }

    .register-container {
        flex-direction: column;
    }

    .register-image-contain {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid var(--argent);
    }

    .register-form {
        width: 100%;
    }

    .register-input-field input {
        /* margin-left: 1rem; */
    }
}