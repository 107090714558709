.addfund-container{
    background-color: #FCFCFC;
   padding: 4rem 1rem 4rem 1rem ;
}
.addfund{
   background-color: white;
    border: 1px solid var(--argent);
    border-radius: .5rem;
    width: 80%;
    margin: 0 auto;
}
.add-fund-border{
    border-bottom: 1px solid var(--argent);
}
.add-fund-border p{ 
    padding: 1rem;
}
.add-fund-card{
    width: 50%;
    
    margin: 1rem auto;
    /* padding-bottom: 3rem; */
  /* display: flex; */
  /* justify-content: center; */
}
.add-enter-input{
    display: flex;
    justify-content: center;
    text-align: center;
}
.add-input{
    border: 1px solid var(--argent);
    font-size: 1rem;
    padding: .5rem;
    outline: none;
    border-radius: .25rem;
}
.add-card-content{
    display: flex;
    margin-top: .5rem;
}
.add-payment{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    text-align: center;
}
.add-fund-radio{
    border: 1px solid var(--argent);
    display: flex;
    align-items: center;
    width: 11rem;
    border-radius: .25rem;
    margin-right: 1rem;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    background-color: white;
    cursor: pointer;
}
.add-fund-radio:hover{
    background-color: white;
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
}
.add-fund-btn-content{
    border-top: 1px solid var(--argent);
    padding: 1rem;
    
}
.card-number-container{
    background-color: white;
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
}
.card-number-input{
    display: flex;
    align-items: center;
}
.add-fund-btn{
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
}
.wallet-container{
display: flex;
justify-content: space-between;
padding: 1rem;
margin-top: 2rem;
margin-bottom: 2rem;
}
.wallet-complete{
    width: 35%;
     padding: 1rem;
     height: 21rem;
    display: flex;
    justify-content: center;
    border: 1px solid var(--argent);
    border-radius: 0.625rem;
}
.wallet-body{
    display: flex;
    align-items: center;
}
.wallet-detail{
    text-align: center;
    margin-top: 1rem;
}
.wallet-deatils-body{
    border: 1px solid var(--argent);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}
.wallet-deatils-body p{
    margin-top: .5rem;
    text-transform: capitalize;
    
}
.wallet-table-details{
    width: 63%;
    border-radius: 0.625rem;
    border: 1px solid var(--argent);
}
.wallet-table-details p{
    padding: 1rem;
}
.wallet-btn{
    
}
@media (max-width:768px) {
    .wallet-container{
        flex-direction: column;
    }
    .wallet-complete{
        width: 100%;
    }
    .wallet-table-details{
        width: 100%;
        margin-top: 1rem;
    }
}