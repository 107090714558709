.about {
    background-image: url("../../assets/images/Rectangle\ 10\ \(1\).svg");
    background-repeat: no-repeat;
    background-size: cover;
}

.about-container {
    width: 60%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin: 0 auto;
}

.about-container .h-about,
.h-para {
    text-align: center !important;
    margin-bottom: 1.5rem;
}

.about-content {
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;
    justify-items: center;
    /* padding-bottom: 8rem; */
}

/* .abt-img {
  width: 40%;
  
}
.abt-img img { 
width: 25rem;
position: absolute;
left: 10rem;
} */
.abt-img img {
    width: 100%;
}

.abt-img-content {
    width: 65%;
    margin: 2rem 0 0 1rem;
}

.ourteam-container h2 {
    font-size: 1.5rem;
    padding: 2rem 4rem 1rem 4rem;

    /* padding-bottom: 1rem; */
}

.ourteam {
    display: flex;
    justify-content: space-between;
    padding: 0rem 4rem 4rem 4rem;
}

.teamcard {
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: 45%;
    box-shadow: 0 0.5rem 1.5rem rgb(0 0 0 / 10%);
    min-height: 20rem;
    margin: 1rem;
    overflow: hidden;
    max-height: 100%;
}

.teambody {
    display: flex;
    /* justify-content:space-around; */
    justify-items: center;
    align-items: center;
}

.teambody .team-image {
    height: 124px;
    width: 124px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #0c0c0c14; */
    /* box-shadow: 0 0 1px #5a5a5a */
}

.teambody img {
    width: 100%;
    object-fit: contain;
}

.team-title {
    margin: 0 2rem 0 2rem;

}

.team-title p {
    color: #272B41;
    font-size: .875rem;

}

.teambody-content {
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
}

.teambody-content p {
    color: #272B41;
    font-weight: normal !important;
    font-size: 1rem;
}

.about-clinic {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    padding: 0 4rem 8rem 4rem;

}

.why {
    background-image: url("../../assets/images/Rectangle\ 5.svg");
    background-repeat: no-repeat;
    width: 25%;
    background-size: cover;
    object-fit: cover;
    background-position: center;
    object-position: center;
    padding: 2rem;
    color: white;
    border-radius: 1rem;
}

.why p {
    font-size: 1rem;
    margin-top: 1rem;
    color: white;
}

.about-clinic-right {
    width: 70%;
}

.clinic-img {
    text-align: center;
}

.clinic-img div {
    margin: auto;
    width: 5rem;
    height: 5rem;
}

.cl-para {
    text-align: center;
    color: #272B41;
    margin-top: 1rem;
}

.design-about {
    position: absolute;
    right: 0rem;
    bottom: -4rem;
}

@media (max-width: 720px) {
    .about-container {
        width: 100%;
        padding: 0.5rem;
    }

    .about-content {
        flex-direction: column;
    }

    .abt-img-content {
        width: 100%;
        margin: 0;
        padding: 0.5rem;
    }

    .ourteam {
        flex-direction: column;
        padding: 0.5rem;
    }

    .teamcard {
        width: 100%;
        margin-top: 1rem;
        /* padding: 0.5rem; */
    }

    .teambody {
        flex-direction: column;
        /* padding: 0; */
        /* margin: 0; */
    }

    .team-title p {
        font-size: 0.7rem;
    }

    .teambody-content {
        padding-bottom: 3rem;
    }

    .teambody-content p {
        font-size: 0.8rem;
    }

    .about-clinic {
        flex-direction: column;
        padding: 0.5rem;
    }

    .why {
        width: 100%;
    }

    .about-clinic-right {
        width: 100%;
        margin-top: 1rem;
    }

    .cl-para {
        font-size: 0.8rem;
    }
}