.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 2rem 2rem 1.8rem;
  width: 100%;
  height: 5rem;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* z-index: 100; */
}

.login-btn {
  background-color: var(--steel-blue);
  cursor: pointer;
  padding: 0.6rem 1.2rem 0.6rem 1.2rem;
  color: white;
  border-radius: 1.5rem;
  border: none;
  outline: none;
}

.header .lang-select {
  border: 1px solid #f4f4f4;
  background-color: white;
  padding: 0.5rem 0rem;
  margin-left: 1rem;
  border-radius: 0.2rem;
}

.logo-header {
  display: flex;
  justify-items: center;
  /* justify-content:center; */
  align-items: center;
  width: 20%;
}

.header .logo {

  width: 120px;
  max-height: 100%;
}

#menu-btn {
  display: none;
  height: 2.5rem;
  width: 3.5rem;
  line-height: 2.5rem;
  border-radius: 0.5rem;
  background-color: #eee;
  color: black;
  font-size: 1.5rem;
  margin-left: 1.6rem;
  cursor: pointer;
  text-align: center;
}

#close-btn {
  display: none;
  height: 2.5rem;
  width: 3.5rem;
  line-height: 2.5rem;
  border-radius: 0.5rem;
  background-color: #eee;
  color: black;
  font-size: 1.5rem;
  margin-left: 1.6rem;
  cursor: pointer;
  text-align: center;
}

.popup {
  display: none;
  border: none;
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
}

.links {
  display: flex;
  align-items: center;
  width: 70%;
  list-style: none;
  justify-content: space-between;
  /* margin-left: 10rem; */
}

.list-nav {
  display: flex;
}

.navlink {
  color: #707070;
  margin: 0 2rem 0 2rem;
  display: flex;
}

.activeLink {
  font-weight: 600;
  color: var(--tufts-blue);
  border-bottom: 2px solid var(--tufts-blue) !important;
  /* padding-bottom: 5px; */
  display: flex;
  margin: 0 2rem 0 2rem;
}

.active-popup {
  display: block;
  right: 2rem;
  position: absolute;
  top: 4.5rem;
}

.box-one {
  width: 70% !important;
}

.box-one p {
  font-size: 14px !important;
}

.login-icon {
  width: 2rem;
  height: 2rem;
  border: 1px solid var(--tufts-blue);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-icon .fa-chevron-right {
  font-size: 1rem;
  color: var(--steel-blue);
}

.popup-top {
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.popup-top h4 {
  color: var(--steel-blue);
  cursor: pointer;
}

.popup-top h4:hover {
  text-decoration: underline;
}

.popup-top p {
  font-size: 0.8rem;
  margin-top: 0.4rem;
}


.dropbtn {
  background-color: white;
  width: 8.125rem;
  padding: 4px;
  margin-left: 2rem;
  border: 1px solid #eee;
  border-radius: .25rem;
  font-size: .875rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  box-shadow: 0px 8px 16px 0px rgba(255, 255, 255, 0.7);
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 1rem;
}

.translate {
  width: 24px;
  margin: 5px;
}

.chevronDown {
  width: 16px;
  ;
  margin: 8px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  border-radius: 8px;
  left: 2.063rem;
  /* right: 1rem; */
  width: 8.125rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content span {
  color: black;
  cursor: pointer;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content span:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
  /* padding: 0 1rem  0; */
}

.dropdown:hover .dropbtn {
  background-color: white;
}

.customer_support {
  color: var(--steel-blue);
  margin: 0.3rem;
}

.bell {
  padding: 0 0.75rem;
  padding-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.8rem;
  margin: 0rem 1.1rem;
  color: var(--steel-blue);
  border-right: 2px solid #707070;
  border-left: 2px solid #707070;
  cursor: pointer;
}

.avatar {
  width: 60px;
  cursor: pointer;
  height: 60px;
  background-color: red;
  border-radius: 50%;
}

.book-list-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;


}

.book-nav-content {
  display: flex;
}

.book-profile-nav {
  display: flex;
  align-items: center;
}

.book-nav-para {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

@media (max-width: 991px) {

  .header {
    padding: 2rem;
    width: 100%;
  }

}

@media (max-width: 720px) {
  #menu-btn {
    display: inline-block;

  }

  .header {
    padding: 1rem;
  }

  .logo-header {
    width: 100%;
  }

  #close-btn {
    display: block;
  }

  .dropbtn {
    /* margin-left: 10rem; */
  }


  .links {
    position: fixed;
    left: 100%;

    overflow: hidden;
    height: auto;
    background-color: #fff;
    box-shadow: var(--box-shadow);
  }

  .header .links.is-expended {
    left: 7.5rem;
    top: 5.1rem;
    transition: 0.4s linear;
    flex-direction: column;
  }

  .list-nav {
    flex-direction: column;
  }

  .login-list {
    padding-bottom: 1rem;
  }

  .navlink {
    margin: 1rem 0rem 1rem 0rem;

  }

  .activeLink {
    margin: 1rem 0rem 1rem 0rem;
  }

  .bell {
    margin-top: 1rem;
    margin-left: 0;
  }

  .book-list-nav {
    position: fixed;
    left: 100%;
    width: 60%;
    height: calc(100vh - 500px);
    background-color: #fff;
    flex-direction: column;
    justify-content: center;
    box-shadow: var(--box-shadow);
  }

  .book-profile-nav {
    flex-direction: column;
    margin-top: 1rem;
  }

  .book-nav-content {
    flex-direction: column;
  }

  .book-is-expended {
    left: 7.2rem;
    transition: 0.4s linear;
    top: 4rem;
  }

}


@media (max-width:852px) {
  #menu-btn {
    display: inline-block;

  }

  .header {
    padding: 1rem;
  }

  .logo-header {
    width: 100%;
  }

  #close-btn {
    display: block;
  }

  .dropbtn {
    /* margin-left: 10rem; */
  }


  .links {
    position: fixed;
    left: 100%;

    overflow: hidden;
    height: auto;
    background-color: #fff;
    box-shadow: var(--box-shadow);
  }

  .header .links.is-expended {
    left: 7.5rem;
    top: 5.1rem;
    transition: 0.4s linear;
    flex-direction: column;
  }

  .list-nav {
    flex-direction: column;
  }

  .login-list {
    padding-bottom: 1rem;
  }

  .navlink {
    margin: 1rem 0rem 1rem 0rem;

  }

  .activeLink {
    margin: 1rem 0rem 1rem 0rem;
  }

  .bell {
    margin-top: 1rem;
    margin-left: 0;
  }

  .book-list-nav {
    position: fixed;
    left: 100%;
    width: 60%;
    height: calc(100vh - 500px);
    background-color: #fff;
    flex-direction: column;
    justify-content: center;
    box-shadow: var(--box-shadow);
  }

  .book-profile-nav {
    flex-direction: column;
    margin-top: 1rem;
  }

  .book-nav-content {
    flex-direction: column;
  }

  .book-is-expended {
    left: 7.2rem;
    transition: 0.4s linear;
    top: 4rem;
  }


}