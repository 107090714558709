.term-contact {
    /* background-image: url('../../assets/images/Rectangle\ 10\ \(1\).svg'); */
    /* padding: 1rem 1rem 2rem 2rem; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    object-position: center;
    padding-bottom: 2rem;
    width: 95%;
    margin: auto;
}


.terms-content div {
    /* color: #707070; */
    /* font: normal normal normal 14px/21px; */
    padding-left: 1.5rem;
    text-align: left;
}

.terms-para-content {
    padding-left: 1.5rem;
    margin-bottom: 2rem;
    padding-right: 2.5rem;
    color: #C4C0C0;
    font: normal normal normal 16px/25px;
}

.term-para {
    /* margin-top: 1rem; */
    color: #C4C0C0;
    font-size: 1rem;
    font-weight: normal !important;
}

.term-para-privacy {
    color: #C4C0C0;
    font-size: 1rem;
    margin-top: 1rem;
    font-weight: normal !important;
}

.terms-heading {
    margin: 1.5rem 0;
    text-align: center;
    color: #1A1919;
}


/* Privacy & Policy */

.privacy-contact {
    /* background-image: url('../../assets/images/Rectangle\ 10\ \(1\).svg'); */
    /* padding: 1rem 1rem 2rem 2rem; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    object-position: center;
    padding-bottom: 1rem;
    width: 95%;
    margin: auto;
}

.privacy-contact div {
    padding-left: 1.5rem;
    text-align: left;
    /* color: #707070;
    font: normal normal normal 14px/21px; */
}

.privacy-para-content {
    padding-left: 1.5rem;
    margin-top: .5rem;
    padding-right: 2.5rem;
    color: #C4C0C0;
    font: normal normal normal 16px/25px;
}

.privacy-para-content p {
    margin-bottom: 1rem;
}

.privacy-para {
    /* margin-top: 1rem; */
    color: #C4C0C0;
    font-size: 1rem;
    font-weight: normal !important;
}

.privacy-para-privacy {
    color: #C4C0C0;
    font-size: 1rem;
    /* margin-top: 1rem; */
    font-weight: normal !important;
}

.privacy-heading {
    text-align: center;
    margin: 1.5rem 0;
}