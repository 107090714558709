.banner-img {
  position: relative;
  z-index: 1;
  padding: 2rem;
}
.banner-img img {
  /* height: 25rem; */
  max-width: 100%;
  width: 100%;
}
.banner-container {
  width: 100%;
  /* margin-top: 1rem; */
  padding-bottom: 8rem;
  background-image: url("../../../assets/images/Rectangle\ 10\ \(1\).svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.banner {
  border-radius: 1.3rem;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%);
  z-index: 10;
}
.get {
  color: white;
  text-align: center !important;
  font-size: 2.125rem;
  font-weight: 600;
}
.para {
  margin-top: 0.6rem;
  color: white;
  text-align: center;
  font-size: 1rem;
}
.banner-content .or {
  font-size: 1.2rem;
  color: var(--chinese-silver);
  font-weight: normal;
  text-align: center;
}

.searchcard {
  margin: 1.3rem auto;
  width: 30rem;
  height: 3rem;
  padding-left: 1rem;
  background-color: #fff;
  border-radius: 0.2rem;
  display: flex;
  overflow: hidden;
  align-items: center;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
}
.search-icon {
  width: 2.5rem !important;
}
.searchcard input {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  font-size: 1rem;
  color: var(--argent);
  padding: 1rem 0.5rem;
  text-transform: none;
}
.searchcard img {
  width: 3rem;
  padding-right: 1rem;
  color: var(--picton-blue);
  cursor: pointer;
}
.banner-card-container {
  display: flex;
  width: 100%;
  height: 140px;
  position: absolute;
  z-index: 15;
  bottom: 10rem;
  justify-content: center;
  justify-items: center;
}


.banner-book-card {
  background-color: white;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  margin: 2rem;
  padding: 1.5rem;
  width: 12rem;
  height: 12rem;
  cursor: pointer;
}
.banner-book-img {
  text-align: center;
}

.banner-book-card img {
  width: 5rem;
  height: 5rem;
}
.banner-card-container p {
  color: var(--steel-blue);
  font-size: 1rem;
  text-align: center;
  margin-top: 1rem;
}
.card-para {
  font-size: 0.8rem;
}
.design-circle{
  position: absolute;
left: -6.5rem;
bottom: -8rem;
  /* bottom: 0rem; */
}
@media (max-width: 768px) {
  .banner-container {
    width: 100%;
    padding: 1rem 1rem 5rem 1rem;
  }
  .banner-img {
    position: static;
    padding: 0;
  }
  .banner-img img {
   height: 20rem;
  }
  .banner {
    top: 0%;
  
  }
 
  .banner-content {
    padding: 1rem;
  }
  .get {
    font-size: 1rem;
  }
  .para {
    font-size: 0.8rem;
  }
  .banner-content .or {
    font-size: 0.9rem;
    margin: 0;
  }
  .banner-card-container{
    position: static;
    flex-wrap: wrap;
    height: 1000px;
  }
  .banner-book-card{
    width:100%;
  }
  .searchcard {
    width: 100%;
  }
  .searchcard input {
    font-size: 0.75rem;
    width: 100%;
  }
}
@media (max-width:991px) {
  .banner-container {
    width: 100%;
    padding: 1rem 1rem 5rem 1rem;
  }
  .banner-img {
    position: static;
    padding: 0;
  }
  .banner-img img {
   height: 20rem;
  }
  .banner {
    top: 5%;
    /* transform: translate(0%); */
  }
 
 
  .get {
    font-size: 1rem;
  }
  .para {
    font-size: 0.8rem;
  }
  .banner-content .or {
    font-size: 0.9rem;
    margin: 0;
  }
  .banner-card-container{
    position: static;
    flex-wrap: wrap;
    height: 1000px;
  }
  .banner-book-card{
    width:100%;
  }
  .searchcard {
   
    width: 100%;
  }
  .searchcard input {
    font-size: 0.75rem;
    width: 100%;
  }
  
}