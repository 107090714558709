.card-p-wallet{
    background-color: white;
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.1);
    margin-top: 2rem;
    padding: 1rem;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
}
.overlay-custom-class-name{

  }
.over{
  width: 40rem;
  /* background-color: rgba(0, 0, 0, 0.7); */
  /* border: 1px solid var(--steel-blue); */
  /* padding: 1rem; */
  border:1px solid var(--argent);
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
  height: 15rem;
  border-radius: 0.625rem;
  text-align: center;
  position: relative;
  z-index: 100;
}
.over-head{
    margin-top: 3rem;
}
.over-head h5{
    font-size:16px;
}
.over-head p{
    margin-top: 1rem;
}
.over-btn-head{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    
}
.yes-btn{
    border: none;
    outline: none;
    /* width: 10rem; */
    padding: 0.5rem 2rem 0.5rem 2rem;
    margin: 0rem 1rem 0 1rem;
    border-radius: 0.25rem;
    background-color: var(--steel-blue);
    color: white;
}
.cancel-btn-over{
    border: none;
    outline: none; 
    padding: 0.5rem 2rem 0.5rem 2rem;
    border-radius: 0.25rem;
    color: white;
    background-color: #E80707;
}
.payment-wallet-card{
    display: none;
}
.payment-wallet{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
.card-p{
    background-color: white;
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.1);
    margin-top: 2rem;
    display: none;
    padding: 1rem;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
}
.card-p.tab-active{
display: block;
}

  .payment-wallet-card.tab-active {
    display: block;
  }
.card-p-input{
    display: flex;
    /* justify-content: space-around; */
    
}
.card-p-input label{
    font-size: 0.875rem;
    color: #707070;
}
.card-number{
    width: 50%;
    margin: 0rem 2rem 1rem 2rem;
}
.expiry{
    width: 26%;
    margin: 0rem 1rem 1rem 1rem;
 
}
.card-input{
    border: 1px solid var(--argent);
    width: 100%;
    padding: 0.8rem 1rem .8rem 1rem ;
    outline: none;
    color: #C4C0C0;
    font-size: 1rem;
    border-radius: 0.25rem;
   
}
.expiry-input{
    border: 1px solid var(--argent);
    width: 100%;
    padding: 0.8rem 1rem .8rem 1rem ;
   
    outline: none;
    color: #C4C0C0;
    font-size: 1rem;
    border-radius: 0.25rem;
}
.apply-coupon{
    display: flex;
    margin-top: 2rem;
    align-items: center;
    /* justify-content: space-between; */
}
.apply-coupon p{
color: #707070;
font-size: .875rem;
}
.apply-input{
    border: 1px solid var(--argent);
    width: 40%;
    padding: 0.6rem 1rem .6rem 1rem ;
    outline: none;
    /* color: #C4C0C0; */
    font-size: 1rem;
    margin: 0 2rem 0 2rem;
    border-radius: 0.25rem;
}
.apply-btn{
margin-left: 2rem;
padding: 0.7rem 2rem .7rem 2rem ;
font-size: 1rem;
border: none;
border-radius: 2rem;
background-color: var(--steel-blue);
color: white;
}



.complete-step{

    border: 1px solid#16CB0E;
    background-color: #E9FFE8;
    border-radius: .25rem;
    padding: 1.5rem;
  
    /* width: 30%; */
}
.complete-step-content{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin: 0 auto;
    width: 60%;
}
.complete-step-content p{
    margin-left: 1.5rem;
}
.complete-apt-container{
    margin-top: 2rem;
}
.complete-apt{
    display: flex;
    margin-top: .2rem;
    justify-content: space-between;
    border: 1px solid #F0F0F0;
    background-color: white;
    padding-left: 1rem;
    border-radius: .25rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
}
.complete-apt-para p{
    color: #C4C0C0;
    font-size: 1rem;
    margin-top: 1rem;
}
.complete-apt-para1 p{
    font-size: 1rem;
    color: #1A1919;
    margin-top: 1rem;
    text-align: right;
}
.goto{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}
@media (max-width:720px) {
    .card-p-input{
        flex-direction: column;
        padding: .5rem;
    }
    .card-number{
        width: 100%;
        margin-left: 0;
        margin-top: 1rem;
    }
    .expiry{
        width: 100%;
        margin-left: 0;
        margin-top: 1rem;
    }
    .apply-coupon{
        padding: 0.5rem;
    }
    .complete-step-content{
        flex-direction: column;
        width: 100%;
    }
    .complete-step-content p{
        margin: 0;
        text-align: center;
    }
}