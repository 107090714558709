.wet-content-btn {
    display: flex;
    align-items: center;
    /* align-items: baseline; */
}

.accept-btn {
    border: none;
    border-radius: 2rem;
    background-color: var(--steel-blue);
    padding: .7rem 2rem .7rem 2rem;
    font-size: 1rem;
    margin-top: 1rem;
    color: white;
}


.rescPopup {
    background-color: var(--steel-blue);
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
    padding: 1rem;
}

.rescPopup h4 {
    color: white;
    font-weight: normal;
    font-size: 1rem;
}

.rescCheck {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
}

.rescCheck .wrong-input {
    margin-left: 3rem;
}

.reshText {
    margin-top: .5rem;
    /* border-bottom: 1px solid var(--argent); */
}

.reshText textarea {
    width: 100%;
    padding: 0.9rem;
    border: 1px solid var(--argent);
    border-radius: 0.3rem;
    font-size: 1rem;
    height: 10rem;
    margin-top: 0.5rem;
    outline: none;
}

.reshbtn-content {
    border-top: 1px solid var(--argent);
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.reshbtn-content div {
    width: 20%;
    margin: 0 2rem;
}

.wet-input-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wet-form-content {
    width: 47%;
    /* margin-top: 1rem; */
}

.reshText textarea {
    width: 100%;
    padding: .9rem;
    border: 1px solid var(--argent);
    border-radius: .3rem;
    font-size: 1rem;
    height: 10rem;
    /* margin-top: .5rem; */

}

.reshbtn-content {
    /* border-top: 1px solid var(--argent); */
    display: flex;
    justify-content: center;

    /* margin: 1rem; */
}

.resh-cancel {
    border: 1px solid var(--steel-blue);
    border-radius: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
    background-color: white;
    color: var(--steel-blue);
    font-size: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    justify-content: center;
    cursor: pointer;
}

.resh-submit {
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: var(--steel-blue);
    border: none;
    padding-left: 3rem;
    justify-content: center;
    padding-right: 3rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-radius: 2rem;
    color: white;
    font-size: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 1rem;
}

.wet-input-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wet-form-content {
    width: 47%;
    /* margin-top: 1rem; */
}

.wet-input {
    width: 100%;
    padding-top: .8rem;
    padding-bottom: .8rem;
    padding-left: 1rem;
    font-size: 1rem;
    padding-right: 1rem;
    border: 1px solid var(--argent);
    margin-top: .5rem;
    border-radius: .3rem;
    outline: none;
}

.scroll {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;

    /* position: absolute; */
    /* bottom: 6.5rem;
    top: 0rem; */
}

.wet-file {
    border: 1px solid var(--argent);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 60%;
    padding: 0.5rem;
    border-radius: 0.3rem;

}

.wet-file input {
    padding: 0.5rem;
    border: 1px solid var(--steel-blue);
    border-radius: 2rem;
    outline: none;
    /* border: none; */
    /* opacity: 0.5; */

    /* position: absolute; */

}

.end-wet-call {
    background-color: var(--steel-blue);
    color: white;
    padding: 1rem;
}

.rating-wet {
    padding: 1rem;
}

.wet-text {
    width: 100%;
    border: 1px solid var(--argent);
    padding: 2rem;
    height: 10rem;
}

.rating-content {
    padding: 1rem;
}

.rating-content p {
    color: #707070;
    font-size: 0.875rem;
}

.rating-star {
    display: flex;
    align-items: center;
    margin-top: .5rem;
    /* justify-content: space-betwee; */
}

.rating-feed {
    margin-top: 2rem;
}

.rating-feed label {
    color: #707070;
    font-size: .875rem;
}

.rating-feed textarea {
    width: 100%;
    padding: .5rem 1rem;
    border: 1px solid var(--argent);
    border-radius: .25rem;
    height: 10rem;
    margin-top: .5rem;
    outline: none;
}